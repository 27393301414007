import React from 'react';
import ReactDOM from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.min.css';
import 'animate.css';
import ReactGA from "react-ga4";
import Initial from "./Initial";


ReactGA.initialize("G-HYE8MH90GS");
ReactDOM.render(
    <React.StrictMode>
      <div>  <Initial/></div>      
       <div> <App />    </div>        
    </React.StrictMode>,
    document.getElementById("root")
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);



  