import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import {GetUserDetail , WebGetExpiredMagzine } from '../../src/components/APISCollection';
import Cookies from 'js-cookie';


export default function TransitionAlerts() {
  const localCurrency = sessionStorage.getItem("LocalCurrency");
  const saleCurrency = localCurrency ? localCurrency : "₹";
  const [show , setShow] =useState(true)
  const userID = Cookies.get('UserID');
  const user = (userID === "guest" ? "gf" : userID);

  const [expiredDate, setExpiredDate] = useState([]);
  const [openAlerts, setOpenAlerts] = useState([]);
  const [details , setDetails ] =useState([]);
  useEffect(() => {
    const storedData = localStorage.getItem('myStoredData');
    const storedTimestamp = localStorage.getItem('myStoredTimestamp');
    const expirationTime = 10 * 60 * 1000; // 10 minutes in milliseconds

    // If there's no stored data or it's expired, fetch data and show the UI
    if (!storedData || (storedTimestamp && Date.now() - parseInt(storedTimestamp, 10) > expirationTime)) {
      GetUserDetail(user, 1).then(result => {
        setDetails(result.data[0].CustName);
        console.log(result.data);
      });

      WebGetExpiredMagzine(user, 'ExpiredSubscriptions')
        .then(result => {
          if (result.data[0].Result !== "Failed to retrive your Magazine") {
            setExpiredDate(result.data);
            setOpenAlerts(Array(result.data.length).fill(true));
            document.getElementById("expDiv").style.display = "block";
            setShow(true)
            console.log(result.data);
          } else {
            setShow(false)
           
          }
        })
        .catch(error => {
          console.error("Error fetching expired magazines:", error);
        });

      // Store the data along with the current timestamp
     
    }
  }, [user]);

  const handleAlertClose = (index) => {
     localStorage.setItem('myStoredData', 'yourDataHere');
      localStorage.setItem('myStoredTimestamp', Date.now().toString());
    const updatedOpenAlerts = [...openAlerts];
    updatedOpenAlerts[index] = false;
    setOpenAlerts(updatedOpenAlerts);
  };

  return (
    <>{show?<>
    {expiredDate.map((subscription, index) => (
        <Box key={index} sx={{ width: '100%' }}>
          <Collapse in={openAlerts[index]}>
            <Alert
              icon={false}
              style={{ backgroundColor: '#f3ebeb' }}
             
              sx={{ mb: 2 }}
            >  <div className='Expireproduct  AlertBox'>
              <div className='Expirestyle'><strong>Dear {details}, Your subscription plan of {subscription.BookName} has expired. Please purchase again.</strong></div>
              <div className='Expirestyle2' style={{display:'flex'}}> <div><Link target="_parent" to={"product-detail?q=" + subscription.BookCode} className="btn btn-primary w-100 btnhover btn-sm outStockBTN">Buy </Link>
              </div>&nbsp;<div><span  className="btn btn-primary w-100 btnhover btn-sm  outStockBTN"   onClick={() => handleAlertClose(index)}>X</span></div>
              </div>
            </div>
            </Alert>
          </Collapse>
        </Box>
      ))}</>:<></>}
      
    </>
  );
}
