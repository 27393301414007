import React,{useLayoutEffect} from 'react'

import {WebGetCompanyDetail} from "./components/APISCollection"
 const  Initial=()=>{
    useLayoutEffect(() => {
  
  
      WebGetCompanyDetail().then(result=>{
  
          sessionStorage.setItem("CompanyName", result.data[0].CompanyName)
          sessionStorage.setItem("iCompanyID", result.data[0].iCompanyId)
          sessionStorage.setItem("iBranchID", result.data[0].iBranchID) 
          sessionStorage.setItem("FinancialPeriod", result.data[0].FinancialPeriod)
          
      })
    },[])
  
      return(
          <></>
      );
  }
  export default Initial;